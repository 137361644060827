<template>
    <div>
        <div class="highlight">Der erste Schritt auf dem richtigen Weg</div>
        <div class="box">
            <h1>Kontakt</h1>
            <div class="columns">
                <div>
                    <p>
                        <b>selectCOM</b><br>
                        <span class="grey">
                            Georg-Reismüller-Str. 49<br>
                            80999 München Deutschland
                        </span>
                    </p>
                    <p>
                        <span title="Telefon"><fa icon="phone"/> +49 89 9545713-65</span><br>
                        <span class="grey" title="Fax"><fa icon="fax"/> +49 89 8982989439</span>
                    </p>
                    <p title="E-Mail">
                        <fa icon="envelope"/> info@avalon-orga.de
                    </p>
                </div>
                <formular
                    @submit="erfolgreich = true"
                    @error="erfolgreich = false">
                    <template #cookie-text>
                        <p>
                            Ihre Privatsphäre ist uns wichtig.<br>
                            Um das Formular auszufüllen müssen Sie der Nutzung von
                            Cookies zustimmen.
                            Die Cookies
                            (<a href="https://www.google.com/recaptcha/about/" target="_blank">google <fa icon="external-link-alt"/></a>)
                            beziehen sich alleine auf ein captcha und stellen sicher,
                            dass Sie kein Roboter sind.
                            Ausserhalb des Formulars nutzt unsere Webseite keine Cookies.<br>
                        </p>
                    </template>
                </formular>
            </div>

            <h3 style="margin-top: 2em;" v-if="erfolgreich === true">
                Vielen Dank für Ihr Interesse. Wir kontaktieren Sie kurzfristig,
                erläutern das Leistungsspektrum von AVALON Orga und gehen gerne auf Ihre Fragen ein.
            </h3>
            <h3 style="margin-top: 2em;" v-if="erfolgreich === false">
                Beim senden des Formulars ist ein Fehler aufgetreten.
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    data() {return{
        erfolgreich: null
    }}
}
</script>

<style lang="scss" scoped>
p {
    & + & {
        margin-top: 2em;
    }
}

b {
    font-size: 1.3em;
}

svg {
    color: #1a1a1a;
}

.grey {
    color: #555;
    font-size: .9em;

    svg {
        color: #333
    }
}
</style>